import '../css/reusable.css';

/**
 * A Box is simply a div container that is used to add a consistently styled
 * green box around whatever children you want to put in there. A box contains
 * different styles for some text elements.
 * @param {*} children props.children react attribute. The child components.
 * @returns the Box component.
 */
const Box = ({ children }) => {
  return <div className='box'>{children}</div>;
};

const SmallBox = ({ children }) => {
  return <div className='box small'>{children}</div>;
};

const Button = ({
  text = 'click',
  action = () => {},
  id = undefined,
  disabled = false
}) => {
  return (
    <button id={id} onClick={action} disabled={disabled}>
      {text}
    </button>
  );
};

// /**
//  * A SlideShow is a element which contains multiple Slides which will be able
//  * to be alternated, either on a timer or on a
//  * @returns The
//  */

/**
 *
 * @param {*} param0
 * @returns
 */
const SlideShow = ({ children }) => {
  return <div className='SlideShow'></div>;
};

const Slide = () => {};

export { Box, SmallBox, Button, SlideShow, Slide };
