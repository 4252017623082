// Stuff from react libraries.
import { createContext, useContext, useEffect, useState } from 'react';
// CSS
import '../../css/contactpage.css';

import {
  validateName,
  validateEmail,
  validatePhone,
  validateHeaderMessage
} from '../../functions/validators';
import { requestSendContactFormEmail } from '../../functions/requests';
import { Box, Button } from '../reusable';

/** The ContactContext keeps track of the state of the message. */
const ContactContext = createContext();

/**
 * The ContactPage is found at the /contact path. It contains some text and the
 * ContactForm component used to send me an email. ContactPage provides the
 * ContactContext to all its children. It also holds the logic for the sending
 * of the email.
 */
const ContactPage = () => {
  const [contactState, setContactState] = useState({
    name: '',
    email: '',
    phone: '',
    header: '',
    message: '',
    sendable: false,
    send: false,
    sent: false,
    feedback: ''
  });

  // Handle sending of email.
  useEffect(() => {
    // Only should work if we have not sent an email yet.
    if (contactState.send && !contactState.sent) {
      // Define (and immediately call) an asynchronous function to send the
      // request.
      (async () => {
        // Make the request.
        const response = await requestSendContactFormEmail(
          contactState.name,
          contactState.email,
          contactState.phone,
          contactState.header,
          contactState.message
        );
        // Deal with the response.
        if (response.messageSent) {
          // Complete success!
          if (response.confirmationSent) {
            setContactState({
              ...contactState,
              feedback: `Email sent successfully. A confirmation email was sent to
                ${contactState.email}. Please expect a response within 2 business
                days.`,
              sendable: false
            });
            return;
          }

          // Confirmation email could not be sent to sender.
          setContactState({
            ...contactState,
            feedback: `Email sent successfully. There was a problem delivering
              a confirmation email to ${contactState.email}. If this email is
              correct, you can expect a response within 2 business days.`,
            sendable: false
          });
        } else {
          // Message could not be sent to Devin.
          // If this is the case, we reset the contactState so a send can be
          // reattempted.
          setContactState({
            ...contactState,
            sent: false,
            send: false,
            sendable:
              contactState.name &&
              contactState.email &&
              contactState.phone &&
              contactState.header &&
              contactState.message,
            feedback: `Message could not be sent! Reason: ${response.message}`
          });
        }
      })();

      // Change sent to true so that the email cannot be sent many times.
      setContactState({
        ...contactState,
        sent: true
      });
    }
  }, [contactState.send]);

  const initialContactContext = { contactState, setContactState };

  return (
    <div className='ContactPage'>
      <ContactContext.Provider value={initialContactContext}>
        <h1>Get my attention!</h1>
        <p>
          Have you explored my site, and want to get in touch? Send me a message
          using this form, and I will get back to you as soon as I can!
        </p>
        <Box>
          <ContactForm />
        </Box>
      </ContactContext.Provider>
    </div>
  );
};

/**
 * The Contact Form contains all fields needed to send me an email.
 * It has built in client-side validation.
 * It holds the logic of determining whether the send button is active or not.
 */
const ContactForm = () => {
  const { contactState, setContactState } = useContext(ContactContext);
  console.log(contactState);

  // Whenever input fields change, check to see if the button should be active.
  useEffect(() => {
    const sendable =
      contactState.name &&
      contactState.email &&
      contactState.phone &&
      contactState.message &&
      contactState.header;
    setContactState({
      ...contactState,
      sendable: sendable
    });
  }, [
    contactState.name,
    contactState.email,
    contactState.phone,
    contactState.message,
    contactState.header
  ]);

  return (
    <div className='ContactForm'>
      <CFGroup label='First and last name' id='name' validator={validateName} />
      <CFGroup label='Email address' id='email' validator={validateEmail} />
      <CFGroup label='Phone number' id='phone' validator={validatePhone} />
      <CFGroup
        label='Subject'
        id='header'
        rows={2}
        validator={validateHeaderMessage}
      />
      <CFGroup
        label='Message'
        id='message'
        rows={10}
        validator={validateHeaderMessage}
      />
      <Button
        text='Send email'
        action={() => {
          // All we need to do is set send to true.
          setContactState({
            ...contactState,
            send: true
          });
        }}
        id='sendbutton'
        disabled={!contactState.sendable}
      />
      <span>{contactState.feedback}</span>
    </div>
  );
};

const CFGroup = ({ label, id, rows = 1, validator }) => {
  const { contactState, setContactState } = useContext(ContactContext);

  /**
    This is the onblur function for the inputs.
    If the input is valid, it will add it to the ContactContext.
    If not, it will clear the value.
    It will also provide visual feedback to the user.
  */
  const validateField = () => {
    // Determine validity
    const element = document.querySelector(`#${id}`);
    const input = element.value.trim();
    const valid = validator(input);
    // Give visual feedback
    if (valid) {
      element.style.borderLeft = '2px solid var(--spearmint)';
      element.style.borderTop = element.style.borderLeft;
      element.style.borderRight = '2px solid var(--charcoal)';
      element.style.borderBottom = element.style.borderRight;
    } else {
      element.style.borderLeft = '2px solid var(--light-rust)';
      element.style.borderTop = element.style.borderLeft;
      element.style.borderRight = '2px solid var(--rust)';
      element.style.borderBottom = element.style.borderRight;
    }
    // Update context
    setContactState({
      ...contactState,
      [id]: valid ? input : ''
    });
  };

  return (
    <div className='CFGroup'>
      <label htmlFor={id}>{label}</label>
      {rows === 1 ? (
        <input type='text' id={id} onBlur={validateField} />
      ) : (
        <textarea id={id} rows={rows} onBlur={validateField}></textarea>
      )}
    </div>
  );
};

export default ContactPage;
