// react stuff
import { useContext } from 'react';
import { Link } from 'react-router-dom';
//css
import '../css/header.css';

import strings from '../text/header.json';
// contexts
import { LanguageContext } from './app';
// images
import bddLogo from '../img/logos/logo_trans_celadon.png';
import hamburger from '../img/icons/hamburger-celadon.png';
import ghLogo from '../img/icons/github-celadon.png';
import liLogo from '../img/icons/linkedin-celadon.png';

/**
 * The Header <header /> element. Contains logo (link to home), github and
 * linkedin links. Also contains <nav />.
 *
 * nav contains links to the pages home, resume, projects, and contact form.
 *
 * @returns The Header component.
 */
const Header = () => {
  /**
   * The onClick function for the hamburger icon.
   * The hamburger is only visible on mobile devices (thin screens) - therefore,
   *
   */
  const flipNavVisibility = () => {
    const nav = document.querySelector('nav');
    switch (nav.classList.contains('invisible')) {
      case true:
        nav.classList.remove('invisible');
        break;
      default:
        nav.classList.add('invisible');
    }
  };

  return (
    <div className='Header'>
      <header>
        <div className='int-links'>
          <img
            src={hamburger}
            id='hamburger'
            alt='Hamburger icon to open nav bar.'
            onClick={flipNavVisibility}
          />
          <Link to='/'>
            <img src={bddLogo} alt='BigDevDog logo' />
          </Link>
        </div>
        <div className='ext-links'>
          <a
            href='https://github.com/b1gd3vd0g'
            target='_blank'
            rel='noreferrer'>
            <img src={ghLogo} alt='GitHub logo' />
          </a>
          <a
            href='https://linkedin.com/in/devinpeevy'
            target='_blank'
            rel='noreferrer'>
            <img src={liLogo} alt='LinkedIn Logo' />
          </a>
        </div>
      </header>
      <Nav />
    </div>
  );
};

const Nav = () => {
  const { languageState } = useContext(LanguageContext);
  return (
    <nav
      onMouseLeave={() => {
        document.querySelector('nav').classList.add('invisible');
      }}
      className='invisible'>
      <Link to='/'>
        <div className='nav-link'>
          <h4>{strings.home[languageState]}</h4>
        </div>
      </Link>
      <Link to='/projects'>
        <div className='nav-link'>
          <h4>{strings.projects[languageState]}</h4>
        </div>
      </Link>
      <Link to='/resume'>
        <div className='nav-link'>
          <h4>{strings.resume[languageState]}</h4>
        </div>
      </Link>
      <Link to='/contact'>
        <div className='nav-link'>
          <h4>{strings.contact[languageState]}</h4>
        </div>
      </Link>
    </nav>
  );
};

export default Header;
